import React from 'react';

 
const Testpages = () => {
    return (
        <div>
            Testpages
            <div className='footer'>
                <h3>Footer</h3>
            </div>
        </div>
    );
}
 
 
export default Testpages;