import React, { useEffect, useState } from 'react';
//import { Redirect } from 'react-router';
import { Redirect } from 'react-router';
import * as base from "../global";
import { Link } from 'react-router-dom';
import { Button, Form } from 'reactstrap';
import axios from 'axios';
//pagination npm install react-js-pagination
import Pagination from "react-js-pagination";
import ReactDOM from 'react-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function ModulList(props) {
    const [state, setData] = useState({ users: [] });

    const fetchData = async (pageNumber = 1) => {
        console.log(base.BASE_URL + '/modulelist/' + pageNumber + '/20')
        const api = await fetch(base.BASE_URL + '/modulelist/' + pageNumber + '/5000000');
        //  console.log(await api.json());
        setData({
            users: await api.json()

        });
        window.scrollTo(0, 0);

    };

    useEffect(() => {
        console.log('reload')
        fetchData();
    }, [])


    return (
        <div>

            <table className='table'>

                <tr>
                    <td>Module Name</td>
                </tr>

                {
                    state?.users ?
                        state?.users?.map((result) => (
                            //console.log('' + result);
                            <tr>
                                <td>{result.modulename}</td>
                            </tr>
                        )) : "Loading..."
                }
            </table>
        </div>
    );
}
export default ModulList;
