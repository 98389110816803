import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
//import useForm from 'react-hook-form'
import { Button, Form } from 'reactstrap';
import * as base from "../global";
import axios from 'axios';
import Header from '../../Layout/Header'
import Footer from '../../Layout/Footer'
import Topbar from '../../Layout/Topbar'
//https://stackoverflow.com/questions/41296668/reactjs-form-input-validation
import ActivityIndicator from 'react-activity-indicator'
//npm install react-activity-indicator
import 'react-activity-indicator/src/activityindicator.css'
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ModulList from './ModulList';


export class ModulePage extends Component {

    constructor() {
        super();
        this.register = this.register.bind(this);
        this.handlename = this.handlename.bind(this);
        this.state = {
            isLoading: false,
            loaderfile: 'notloading',
            fields: {},
            errors: {},
            loggdin: 'stop',
            users: [],
            modulename: '',
            description: ''
        }


    }
/*     componentDidMount() {
        fetchData();
      }

 */
    handlename(event) {
        this.setState({
            modulename: event.target.value
        })
    }

    register() {

        if (this.state.modulename == "") {
            alert('Insert the Required Fields')
        }
        else {
            this.setState({ loaderfile: 'loading' }) ///Adminloginareacheck  adminlogin Adminloginsetup
            axios.post(base.BASE_URL + '/modulesetup', {
                modulename: this.state.modulename,
                description: this.state.description
            }, {
                headers: {
                    authorization: `bearer ${localStorage.getItem('token')}`
                }
            })
                .then((response) => {
                    if (response.data == "save") {
                        this.setState({ loggdin: 'start', loaderfile: 'notloading' })
                    }
                    else if (response.data == "notfound") {
                        alert("Not Insert");
                    }
                   
                }, (error) => {
                    console.log(error);
                });
        }
        /////////////////////////////////////////////////////////////////////
    }
    render() {
        if (this.state.loggdin == 'start') {
            return <Redirect to="/ModulePage" />
        }
    return (
    <div>
    <Header /> 
    <Container style={{ marginTop: 86 }}>
    <div className="container-fluid">
        <div class="card position-relative">
            <div class="card-header py-3">
                <Link to="/ModulePage">Back</Link>
            </div>
            <div class="card-body">
            <div className="row">
            <div className='col-md-12'>
            <form className="user">
    <div class="form-content">
        <div>
            <div className="form-header">
                <h3 class="m-0 font-weight-bold text-primary">Module Setup</h3>
            </div>
            <p style={{ color: '#000' }}>Please fill with your details</p>
            {
                this.state.loggdin == 'start' ?
                    <Alert variant="success">
                        <Alert.Heading>Saved</Alert.Heading>
                        <p>
                            Information is saved as you submited
                        </p>
                    </Alert>

                    :
                    ''

            }

        <div className="form-row">
            </div>
        <div className="form-row">
            <div className="form-holder col-md-6" style={{ padding: 10 }}>
                <input type="text" className="form-control form-control-user"
                    id="exampleInputEmail" aria-describedby="emailHelp" 
                    placeholder="Module Name"
                    value={this.state.modulename} onChange={this.handlename}
                />
            </div>
        <div className="form-holder col-md-6" style={{ padding: 10 }}>
            <Button type="button" className="btn btn-primary btn-user btn-block" onClick={this.register}>
                Submit
            </Button>
        </div>
            </div>
            <div className="form-row">
            </div>
        </div>
        </div>
        </form>
                    </div>
                </div>
            </div>
        </div>

        <div className="form-row">
{/* Module List */}
        <ModulList />

        </div>

    </div>
</Container>               


            </div>
        );

    }
}
export default ModulePage
