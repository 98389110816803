import React, { useEffect, useState } from 'react';

const GetJSONParagraph = ({ data }) => {
  const [sentences, setSentences] = useState([]);

  useEffect(() => {
    // Check if data is a string and parse it
    if (typeof data === 'string') {
      try {
        // Parse JSON string into an array
        const parsedData = JSON.parse(data);
        
        // Now it's an array, so extract sentences
        if (Array.isArray(parsedData)) {
          setSentences(parsedData.map(item => item.paragraph));
        } else {
          console.error('Parsed data is not an array:', parsedData);
        }
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    } else if (Array.isArray(data)) {
      // If it's already an array, just extract sentences
      setSentences(data.map(item => item.paragraph));
    } else {
      console.error('Data is neither a string nor an array:', data);
    }
  }, [data]);

  return (
    <div>
      <ul>
        {sentences.length > 0 ? (
          sentences.map((paragraph, index) => (
            <li key={index}>{paragraph}</li>
          ))
        ) : (
          <p>No sentences available.</p>
        )}
      </ul>
    </div>
  );
};

export default GetJSONParagraph;
