import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
//import useForm from 'react-hook-form'
import { Button, Form } from 'reactstrap';
import * as base from "./global";
import axios from 'axios';
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import Topbar from '../Layout/Topbar'
//https://stackoverflow.com/questions/41296668/reactjs-form-input-validation
import ActivityIndicator from 'react-activity-indicator'
//npm install react-activity-indicator
import 'react-activity-indicator/src/activityindicator.css'
import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { classes, groups, typelist } from '../utils/constants' //../utils/constants



export class Editsubjectsetup extends Component {

    constructor(props) {
        super(props);
        this.register = this.register.bind(this);
        this.handlecountry = this.handlecountry.bind(this);
        this.handleschoolcollegename = this.handleschoolcollegename.bind(this); //handleclassname
        this.handleclassname = this.handleclassname.bind(this); //handleclassname
        this.handlename = this.handlename.bind(this); //handlegroupname
        this.handlegroupname = this.handlegroupname.bind(this); //handlegroupname handleVersion
        this.handleVersion = this.handleVersion.bind(this); //handlegroupname handleVersion
        this.state = {
            schoolcollegename: '',
            schoolcollegeid: '',
            inistutetype: '',
            subjectname: '',
            classname: '',
            versionname: '',
            groupname: '',
            id: '',
            grouplist: groups,
            website: '',
            isLoading: false,
            loaderfile: 'notloading',
            autoid: this.props.match.params.id,
            classdata: [],
            fields: {},
            errors: {},
            loggdin: 'stop',
            counter: 0,
            states: '',
            country: '',
            statelist: [],
            allsubscribelist: [],
            name: '',
            typelist: typelist
        }


    }
    /* 
      classname: { type: String },
    versionname: { type: String },
    groupname: { type: String },
    */
    componentDidMount() {
        axios.get(base.BASE_URL + '/editsubjectinformation/' + this.state.autoid).then(res => {
            this.setState({
                allsubscribelist: res.data,
                schoolcollegeid: res.data[0].parentid,
                schoolcollegename: res.data[0].schoolcollegename,
                subjectname: res.data[0].name,
                inistutetype: res.data[0].inistute,
                classname: res.data[0].classname,
                versionname: res.data[0].versionname,
                groupname: res.data[0].groupname
            });
            console.log('monir' + res.data[0]._id);
        });
    }
    handlecountry(event) {
        this.setState({
            inistutetype: event.target.value
        })

        this.setState({ statelist: [] });
        ///////////////////////////////
        axios.get(base.BASE_URL + '/getschoollist/' + event.target.value).then(result => {
            this.setState({ statelist: result.data });
        });
        ///////////////////////////////
    }
    handleclassname(event) {
        // alert(event.target.value);
        this.setState({
            classname: event.target.value
        })
    }
    //onChange={this.handlegroupname}>
    handlegroupname(event) {
        // alert(event.target.value);
        this.setState({
            groupname: event.target.value
        })
    }

    handleschoolcollegename(event) {
        ////////////////////////////////////////////////////////////////////////////
        this.setState({ sublist: [] });
        ///////////////////////////////
        axios.get(base.BASE_URL + '/getschoolcollegeinfo/' + event.target.value).then(result => {
            this.setState({ sublist: result.data, schoolcollegeid: result.data[0]._id, schoolcollegename: result.data[0].name });
        });

        this.setState({ classdata: [] });
        axios.get(base.BASE_URL + '/classlist/1/1000', {
            /*   headers: {
                authorization: `bearer ${token}`
              } */
        }).then(res => {
            const filteredData = res.data.filter(item => item.inistutetype === this.state.inistutetype && item.inistuteid === this.state.schoolcollegeid);
            console.log('classlist here' + JSON.stringify(filteredData))
            this.setState({ classdata: filteredData });
        });


        ////////////////////////////////////////////////////////////////////////////   
    }
    handlename(event) {
        this.setState({
            subjectname: event.target.value
        })
    }
    handleVersion(event) {
        this.setState({
            versionname: event.target.value
        })
    }

    register() {

        if (this.state.inistutetype == "" || this.state.schoolcollegeid == "" || this.state.schoolcollegename == "") {
            alert('Insert the Required Fields')
        }
        else {
            alert(this.state.inistutetype);
            this.setState({ loaderfile: 'loading' }) ///Adminloginareacheck  adminlogin Adminloginsetup

            axios.post(base.BASE_URL + '/updatesubject', {
                subjectautoid: this.state.autoid,
                schoolcollegeid: this.state.schoolcollegeid,
                schoolcollegename: this.state.schoolcollegename,
                subjectname: this.state.subjectname,
                inistutetype: this.state.inistutetype,
                classname: this.state.classname,
                versionname: this.state.versionname,
                groupname: this.state.groupname
            }, {
                headers: {
                    authorization: `bearer ${localStorage.getItem('token')}`
                }
            })
                .then((response) => {

                    if (response.data == "save") {
                        this.setState({ loggdin: 'start', loaderfile: 'notloading' })

                    }
                    else if (response.data == "notfound") {
                        alert("Not found");
                    }


                }, (error) => {
                    console.log(error);
                });


        }

        /////////////////////////////////////////////////////////////////////

    }


    render() {
        // alert(this.state.loggdin);
        if (this.state.loggdin == 'start') {
            return <Redirect to="/Subjectsetuplist" />
        }

        return (
            <div>

                <Header /> {/* Slider Menu */}
                <Container style={{ marginTop: 86 }}>
                    <div className="container-fluid">
                        {/* Page Heading */}
                        <div class="card position-relative">
                            <div class="card-header py-3">

                                <Link to="/Subjectsetuplist">Back</Link>
                            </div>
                            <div class="card-body">

                                <div className="row">

                                    <div className='col-md-12'>


                                        <form className="user">

                                            <div class="form-content">
                                                <div>
                                                    <div className="form-header">
                                                        <h3 class="m-0 font-weight-bold text-primary">Edit Subject Setup </h3>
                                                    </div>
                                                    <p style={{ color: '#000' }}> School College ID {this.state.schoolcollegeid} School/College Name: {this.state.schoolcollegename}</p>

                                                    {
                                                        this.state.loggdin == 'start' ?
                                                            <Alert variant="success">
                                                                <Alert.Heading>Saved</Alert.Heading>
                                                                <p>
                                                                    Information is saved as you submited
                                                                </p>
                                                            </Alert>

                                                            :
                                                            ''

                                                    }



                                                    <div className="form-row">
                                                        <div className="form-holder col-md-6"
                                                            style={{ padding: 16 }}>
                                                            Type : {this.state.inistutetype}
                                                            <select onChange={this.handlecountry}
                                                                style={{ padding: 6, backgroundColor: '#f2f2f2' }}
                                                                className="form-control"
                                                            >

                                                                {this.state.typelist?.map((result) => {
                                                                    return (
                                                                        <option value={result.title}
                                                                        selected={this.state.inistutetype === result.title ? true : false}
                                                                        >
                                                                            {result.title}
                                                                        </option>
                                                                    )
                                                                })}

                                                                {/*       <option value="School"
                                                                 selected={this.state.inistutetype === "School" ? true : false}>School</option>
                                                                <option value="College"
                                                                 selected={this.state.inistutetype === "College" ? true : false}>College</option>
                                                                <option value="Admission Test"
                                                                selected={this.state.inistutetype === "Admission Test" ? true : false}>Admission Test</option>
                                                                <option value="Job Interview"
                                                                 selected={this.state.inistutetype === "Job Interview" ? true : false}>Job Interview</option>
                                                                <option value="Medical" selected={this.state.inistutetype === "Medical" ? true : false}>
                                                                Medical
                                                                </option>
                                                                <option value="Professional"  selected={this.state.inistutetype === "Professional" ? true : false}>
                                                                Professional
                                                                </option> */}
                                                            </select>
                                                            <span style={{ color: "red", fontSize: 12 }}>
                                                                {this.state.errors["inistute"]}
                                                            </span>
                                                        </div>
                                                        <div className="form-holder col-md-6" style={{ padding: 10 }}>
                                                            School / College
                                                            <select className="form-control" onChange={this.handleschoolcollegename}>
                                                                <option value={this.state.schoolcollegename}>{this.state.schoolcollegename} </option>
                                                                {
                                                                    this.state.statelist.map((result) => {
                                                                        return (
                                                                            <option value={result._id}>{result.name}</option>
                                                                        )
                                                                    })}
                                                            </select>
                                                        </div>
                                                        <div className="form-holder col-md-6" style={{ padding: 10 }}>
                                                            Class Name {this.state.classname}
                                                            <select className="form-control"
                                                                onChange={this.handleclassname}>
                                                                <option value=""></option>
                                                                {
                                                                    this.state.classdata.map((result) => {
                                                                        return (
                                                                            <option
                                                                                key={result._id}
                                                                                value={result.xclassname}
                                                                                selected={this.state.classname === result.xclassname ? true : false}
                                                                            >
                                                                                {result.xclassname}
                                                                            </option>
                                                                        )
                                                                    })}
                                                            </select>
                                                        </div>

                                                        <div className="form-holder col-md-6" style={{ padding: 10 }}>
                                                            Version
                                                            <select className="form-control"
                                                                onChange={this.handleVersion}>
                                                                <option value={this.state.versionname}>{this.state.versionname}</option>
                                                                <option value="Bangla Version"
                                                                    selected={this.state.versionname === "Bangla Version" ? true : false}
                                                                >Bangla Version</option>
                                                                <option value="English Version"
                                                                    selected={this.state.versionname === "English Version" ? true : false}>English Version</option>
                                                            </select>
                                                        </div>

                                                        <div className="form-holder col-md-6" style={{ padding: 10 }}>
                                                            Group
                                                            <select className="form-control"
                                                                onChange={this.handlegroupname}>
                                                                <option value=""
                                                                ></option>

                                                                {this.state.grouplist.map((result) => {
                                                                    return (
                                                                        <option value={result.title}
                                                                            selected={this.state.groupname === result.title ? true : false}>
                                                                            {result.title}
                                                                        </option>
                                                                    )
                                                                })}
                                                            </select>
                                                        </div>


                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-holder col-md-6" style={{ padding: 10 }}>
                                                            Subject Name
                                                            <input type="text" className="form-control form-control-user"
                                                                id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Subject Name"
                                                                value={this.state.subjectname} onChange={this.handlename}
                                                            />

                                                        </div>
                                                        <div className="form-holder col-md-6" style={{ padding: 10 }}>
                                                            <Button type="button" className="btn btn-primary btn-user btn-block" onClick={this.register}>
                                                                Submit
                                                            </Button>

                                                        </div>
                                                    </div>


                                                    <div className="form-row">

                                                    </div>

                                                </div>
                                            </div>



                                        </form>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Container>

            </div>
        );

    }
}
export default Editsubjectsetup
