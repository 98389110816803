import React, { useEffect, useState } from 'react'
import * as base from "../global";
import axios from 'axios';

const ModuleName = ({ moduleid }) => {

    const [modulename, setModulename] = useState([]);

    useEffect(() => {
        axios
        .get(base.BASE_URL + '/modulelist/1/9999999', {
          headers: {
            authorization: `bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((res) => {
          const d = res.data;
          const filteredData = d.filter((item) =>
          item._id.toLowerCase().includes(moduleid.toLowerCase())
            );
            setModulename(filteredData)

        })
    },[])

  return (
    <div>
       {/*  {moduleid} */}
        {
        modulename.map((result) => {
        return (
                result.modulename
        )
        })}
    </div>
  )
}
export default ModuleName
